var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "card-box login-form",
          attrs: {
            "auto-complete": "on",
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "0px",
          },
        },
        [
          _c("div", { staticClass: "logo" }, [
            _c("img", { attrs: { src: _vm.logo } }),
          ]),
          _c("div", { staticClass: "login-info" }, [
            _vm._v(
              " If you are a client, please log in using your NDIS number."
            ),
            _c("br"),
            _vm._v(
              " If you are a client manager, please log in using your email address. "
            ),
          ]),
          _c(
            "div",
            { staticClass: "el-form-group" },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "email" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container svg-container_login" },
                    [_c("svgicon", { attrs: { name: "user" } })],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      name: "email",
                      type: "text",
                      "auto-complete": "on",
                      placeholder: "NDIS number or email",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svgicon", { attrs: { name: "password" } })],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      name: "password",
                      type: _vm.pwdType,
                      "auto-complete": "on",
                      placeholder: "password",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [_c("svgicon", { attrs: { name: "eye" } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(" Sign in ")]
          ),
          _c("div", { staticClass: "forgot-password" }, [
            _c("a", { attrs: { href: "/forgot" } }, [
              _vm._v("Forgot password"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }