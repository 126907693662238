<template>
    <div class="login-container">
        <el-form
            ref="form"
            auto-complete="on"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="0px"
            class="card-box login-form"
        >
            <div class="logo">
                <img :src="logo" />
            </div>
            <div class="login-info">
                If you are a client, please log in using your NDIS number.<br />
                If you are a client manager, please log in using your email
                address.
            </div>

            <div class="el-form-group">
                <el-form-item prop="email">
                    <span class="svg-container svg-container_login">
                        <svgicon name="user" />
                    </span>
                    <el-input
                        v-model="form.email"
                        name="email"
                        type="text"
                        auto-complete="on"
                        placeholder="NDIS number or email"
                    />
                </el-form-item>

                <el-form-item prop="password">
                    <span class="svg-container">
                        <svgicon name="password"></svgicon>
                    </span>
                    <el-input
                        v-model="form.password"
                        name="password"
                        :type="pwdType"
                        auto-complete="on"
                        placeholder="password"
                        @keydown.native.enter="handleSubmit"
                    />
                    <span class="show-pwd" @click="showPwd">
                        <svgicon name="eye" />
                    </span>
                </el-form-item>
            </div>
            <el-button
                type="primary"
                style="width: 100%"
                :loading="loading"
                @click="handleSubmit"
            >
                Sign in
            </el-button>

            <div class="forgot-password">
                <a href="/forgot">Forgot password</a>
            </div>
        </el-form>
    </div>
</template>

<script>
import {isValidEmail, validateNDISNumber} from '@/utils/validate';
import logo from '@/assets/logo_dark.svg';
import firebase from 'firebase/app';

import VueRouter from 'vue-router';
const {isNavigationFailure, NavigationFailureType} = VueRouter;

export default {
    name: 'login',
    data() {
        const validateEmail = (rule, value, callback) => {
            // check if a valid email address
            if (!isValidEmail(value)) {
                // check if a valid NDIS number
                if (!validateNDISNumber(value)) {
                    callback(new Error('Invalid email or NDIS number'));
                    return;
                }
            }
            callback();
        };
        const validatePass = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error('Invalid Password'));
            } else {
                callback();
            }
        };
        return {
            logo,
            form: {
                email: '',
                password: '',
            },
            rules: {
                email: [
                    {required: true, trigger: 'blur', validator: validateEmail},
                ],
                password: [
                    {required: true, trigger: 'blur', validator: validatePass},
                ],
            },
            loading: false,
            pwdType: 'password',
        };
    },
    methods: {
        showPwd() {
            if (this.pwdType === 'password') {
                this.pwdType = '';
            } else {
                this.pwdType = 'password';
            }
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let userEmail = this.form.email;

                    if (validateNDISNumber(userEmail)) {
                        userEmail = `${userEmail}@tprecords.com.au`;
                    }

                    this.loading = true;
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(
                            userEmail,
                            this.form.password
                        )
                        .then(
                            (user) => {
                                this.$router.push('/').catch((e) => {
                                    // ignore redirect exceptions from route guard
                                    if (
                                        !isNavigationFailure(
                                            e,
                                            NavigationFailureType.redirected
                                        )
                                    ) {
                                        this.loading = false;
                                        this.$notify.error({
                                            title: 'Error',
                                            message:
                                                'You are not authorised to use this service.',
                                        });
                                    }
                                });
                            },
                            (error) => {
                                this.loading = false;
                                this.$notify.error({
                                    title: 'Error',
                                    message: error.message,
                                });
                            }
                        );
                }
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: $grey;

    .svg-container {
        padding: 6px 5px 6px 15px;
        vertical-align: middle;
        width: 30px;
        display: inline-block;

        &_login {
            font-size: 20px;
        }
    }

    .login-form {
        margin-top: 200px;
        position: relative;
        width: 500px;
        padding: 0 35px;
        overflow: hidden;

        .logo {
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 250px;
            }
        }
        .login-info {
            font-size: 13px;
            margin-top: 20px;
        }

        .el-form-group {
            padding: 20px 0;

            .el-form-item {
                background: white;
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                color: #000000;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .el-form-item__content {
                    display: flex;

                    .el-input {
                        height: 52px;
                        width: 85%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0px 1000px white inset !important;
                            -webkit-text-fill-color: #000000 !important;
                        }

                        input {
                            border: 0px;
                            -webkit-appearance: none;
                            border-radius: 0px;
                            color: #000000;
                            height: 47px;
                        }
                    }

                    .show-pwd {
                        position: absolute;
                        right: 10px;
                        top: 7px;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }
    }

    .thirdparty-button {
        position: absolute;
        right: 35px;
        bottom: 28px;
    }

    .forgot-password {
        text-align: center;
        padding: 20px 0;
        font-size: 12px;
        color: $purple_dark;
    }
}
</style>
